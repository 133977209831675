import classNames from 'classnames';

export default ({ name, classes, errors = [], children, ...props}) => {
    const className = classNames(
        [
            `select select-bordered text-base ${classes}`
        ],
        {
            'select-error': errors.length
        }
    );

    return (
        <select 
            id={name}
            name={name}
            {...props}
            className={className}
        >
            {children}
        </select>
    );
};